import { propsPerPage } from '../Views/Properties';
import Helper, { Variables } from './Helper';
import {
  AMENITIES_FULL,
  AMENITIES_NAMES,
  TAemnities,
} from './amenities/amenities';
import { FilterKeysNames, TRecentParams } from './filter/FilterContainer';
import {
  IProperty,
  TPropertyPhoto,
} from './interfaces/hostfully.property.interface';
import translate from '../components/languages';

export type AmenityKey = keyof typeof AMENITIES_NAMES;

export const HELPER_TSX = {
  goToPartners: function () {
    window.location.href = '/partners';
  },
  fromAmenitieIndexToName: function (amenitieindex: AmenityKey) {
    return AMENITIES_NAMES[amenitieindex];
  },
  prepareRouteCheckout: function (property: IProperty, filters: any) {
    let strParams = '';

    if (filters?.minimumGuests) strParams += `&guests=${filters.minimumGuests}`;
    if (filters?.checkInDate) strParams += `&checkIn=${filters.checkInDate}`;
    if (filters?.checkOutDate) strParams += `&checkOut=${filters.checkOutDate}`;
    return '/checkout?propertyId=' + property.uid + strParams;
  },
  getRecentParams: function (locationParams: any) {
    const page = parseInt(Helper.getParam('page', locationParams)) || 1;
    const kind = Helper.getParam('typeShow', locationParams);
    const favSelected = kind === FilterKeysNames.favorites;
    const params: TRecentParams = {
      checkInDate: Helper.getParam('checkIn', locationParams),
      checkOutDate: Helper.getParam('checkOut', locationParams),
      location: Helper.getParamNew('location', locationParams),
      minimumGuests: Helper.getParam('guests', locationParams) || 1,
      typeProperties: Helper.getParam('typesProperties', locationParams),
      customAmenities: Helper.getParam('customAmenities', locationParams),
      minimumBathrooms: Helper.getParam('minimumBathrooms', locationParams),
      minimumBedrooms: Helper.getParam('minimumBedrooms', locationParams),
      orderBy: Helper.getParam('orderBy', locationParams),
      kindOfProperties: '',
      page: page,
      limit: Variables.regularLimit,
      offset: (page - 1) * propsPerPage,
      favorites: favSelected,
    };

    return params;
  },
  preparePhotosCheckout: function (
    photos: TPropertyPhoto[],
    roomPhotos: TPropertyPhoto[],
  ) {
    const carouselItems = photos.map((eachPhoto, i) => {
      return (
        <img
          src={eachPhoto.mediumScaleImageUrl}
          className="checkoutSmallPic"
          alt="Loading..."
          key={i}
        />
      );
    });
    const purePhotosRoomArray = photos;
    const photosRoomList = roomPhotos.map((each, k) => {
      return (
        <a
          href="#"
          className="d-flex flex-column align-items-start checkout-custom-carousel"
        >
          <img
            src={each.mediumScaleImageUrl}
            className="checkout-bed-pic"
            alt="bed images"
          />
          <span className="checkout-bed-title">
            {translate.t('checkout.beedroom').toString()}
          </span>
        </a>
      );
    });

    return { carouselItems, purePhotosRoomArray, photosRoomList };
  },
};

export const getAmenities = (property: IProperty) => {
  const Agenerales = AMENITIES_FULL.filter(
    (each) =>
      TAemnities.GENERAL === each.category &&
      property?.amenities.some(
        (propAmenity) => propAmenity.amenity === each.amenity,
      ),
  );
  const Abathroom = AMENITIES_FULL.filter(
    (each) =>
      TAemnities.BATH === each.category &&
      property?.amenities.some(
        (propAmenity) => propAmenity.amenity === each.amenity,
      ),
  );
  const Akitchen = AMENITIES_FULL.filter(
    (each) =>
      TAemnities.KITCHEN === each.category &&
      property?.amenities.some(
        (propAmenity) => propAmenity.amenity === each.amenity,
      ),
  );
  const Ainfra = AMENITIES_FULL.filter(
    (each) =>
      TAemnities.INFRA === each.category &&
      property?.amenities.some(
        (propAmenity) => propAmenity.amenity === each.amenity,
      ),
  );

  return { Agenerales, Ainfra, Akitchen, Abathroom };
};
