import React, { useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import right from '../../assets/11. FICHA/right.svg';
import left from '../../assets/11. FICHA/left.svg';
// import Modal from 'react-modal';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Carousel from 'react-multi-carousel';
import { BrowserView } from 'react-device-detect';

const handleDragStart = (e) => e.preventDefault();

const CheckoutCarousel = ({
  items,
  pureItems,
  setPagination = false,
  infinite = true,
  showArrows = false,
  showAllBtn = true,
}) => {
  const [index, setIndex] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const carouselFull = useRef(null);

  const responsive = {
    0: { items: 1 },
    678: { items: 3 },
    1280: { items: 3 },
    // 1350: { items: 4 },
    // 2000: { items: 5 },
    // 2500: { items: 6 },
    // 2800: { items: 7 },
  };

  const renderSlideInfo = ({ item, itemsCount }) => {
    if (setPagination) setPagination(`${item}\\${itemsCount}`);
  };

  const changeSlide = (nextIndex) => {
    const newIndex = index + nextIndex;
    if (newIndex < 0 || newIndex >= items.length) return;
    setIndex(newIndex);
    setCurrentIndex(newIndex);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const renderNextButton = ({ isDisabled }) => {
    return (
      <img
        src={right}
        alt="next button"
        className="alice-custom-right-btn"
        style={{ opacity: isDisabled ? '0.5' : 1 }}
      />
    );
  };

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <img
        src={left}
        className="alice-custom-left-btn"
        alt="previous button"
        style={{
          width: '16px',
          opacity: isDisabled ? '0.5' : 1,
          position: 'absolute',
          left: '5vw',
          bottom: '-12px',
        }}
      />
    );
  };

  const pureItemsImg = pureItems.map((eachPhoto, i) => {
    return <img src={eachPhoto.originalImageUrl} alt="Loading..." key={i} />;
  });

  return (
    <div style={{ position: 'relative' }}>
      <BrowserView>
        {showAllBtn && items.length > 0 && (
          <button
            className="view-all-propics"
            onClick={() => setModalIsOpen(true)}
          >
            Ver todas las fotos
          </button>
        )}
      </BrowserView>
      <div className="container-top-carousel ">
        <AliceCarousel
          mouseTracking
          items={items}
          infinite={infinite}
          renderPrevButton={showArrows && renderPrevButton}
          renderNextButton={showArrows && renderNextButton}
          responsive={responsive}
          animationType="fadeout"
          disableButtonsControls={showArrows ? false : true}
          renderSlideInfo={renderSlideInfo}
          renderDotsItem={false}
          disableDotsControls={true}
          disableSlideInfo={false}
        />
      </div>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        center
        classNames={{ modal: 'fullScreenModal' }}
        styles={{
          modal: {
            maxWidth: 'none',
            width: '100%',
            margin: 0,
          },
        }}
      >
        <AliceCarousel
          // mouseTracking
          items={pureItemsImg}
          infinite={true}
          // controlsStrategy="responsive"
          animationType="fadeout"
          disableButtonsControls
          disableDotsControls
          ref={carouselFull}
        />

        <div
          className="d-flex justify-content-between align-items-center "
          style={{ height: '100%' }}
        >
          <button
            className="btn btn-secondary"
            onClick={(e) => carouselFull?.current?.slidePrev(e)}
            style={{
              position: 'absolute',
              top: '45%',
              left: 20,
              transform: 'translateY(-50%)',
              background: 'white',
              borderRadius: '100%',
              width: 60,
              height: 60,
            }}
          >
            <img src={left} alt="next button" style={{ width: 20 }} />
          </button>

          <button
            className="btn btn-secondary"
            onClick={(e) => carouselFull?.current?.slideNext(e)}
            style={{
              position: 'absolute',
              top: '45%',
              right: 20,
              transform: 'translateY(-50%)',
              background: 'white',
              borderRadius: '100%',
              width: 60,
              height: 60,
            }}
          >
            <img src={right} alt="next button" style={{ width: 20 }} />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CheckoutCarousel;
